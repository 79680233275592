@import url("https://fonts.googleapis.com/css2?family=Poppins&family=Ubuntu:wght@300&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #191919;
  overflow-x: hidden;
}

.front {
  height: 100vh;
  width: 100%;
}

.intro {
  position: absolute;
  top: 40%;
  left: 10%;
}

h1 {
  font-family: "poppins", sans-serif;
  font-weight: 900;
  font-size: 3rem;
  color: rgb(145, 145, 145);
}

.subtitles {
  color: grey;
  font-family: "Ubuntu", sans-serif;
  font-size: 1.5rem;
}

.homeSvg {
  position: absolute;
  right: 7%;
}

.contactSvg {
  position: absolute;
  left: 7%;
  @media (max-width: 900px) {
  }
}

p {
  font-family: "Ubuntu", sans-serif;
}

.Github_Icon:hover {
  color: blueviolet;
  transition: color 0.7s ease-out;
}

.LinkedIn_icon:hover {
  color: blueviolet;
  transition: color 0.7s ease-out;
}
